import React from 'react'


export default function TermsAndService() {
    return (

        <div className="conditionsTerms container-fluid">
            <div className="row justify-content-center">
                <div className="col-10 col-md-10 col-lg-7 col-xl-6">


                    <h1>Conditions d’Utilisation des Services Aliz</h1>

                    <h2 className="catch-phrase">Mise à jour : le 09/07/2021</h2>


                    <h2 className="catch-phrase">1. Préambule</h2>

                    <p className="conditionsTerms-text">
                        La Société TED (ci-après « <b>TED</b> » ou le « <b>Prestataire</b> ») édite et gère les services
                        d’assistants virtuels ALIZ (ci-après les « <b>Services ALIZ</b> » ou « <b>Services</b> »).
                        Chaque professionnel souscrivant aux Services ALIZ (ci-après « <b>Client</b> ») ainsi que les personnes autorisées
                        par le Client à utiliser les Services (ci-après « <b>Utilisateur</b> ») doit prendre connaissance des présentes
                        Conditions d’Utilisation des Services Aliz (ci-après « <b>CUSA</b> »).
                    </p>

                    <div className="conditionsTerms-text border p-4">
                        <b>
                            L’utilisation des Services ALIZ est soumise au respect, par le Client et par les Utilisateurs,
                            des présentes CUSA ainsi que des <a href="https://aliz.io/conditions-generales/cgv">Conditions Générales de Vente</a>.
                        </b>
                    </div>
                    <p className="conditionsTerms-text">
                        Pour toute information ou question concernant les présentes CUSA, le Client peut contacter TED en envoyant
                        un mail à l’adresse : support@aliz.io.
                    </p>






                    <h2 className="catch-phrase">2. Objet</h2>

                    <p className="conditionsTerms-text">
                        Les CUSA décrivent les conditions et modalités d’utilisation des Services ALIZ et déterminent
                        les droits et les obligations respectifs du Client et de TED dans le cadre de l’utilisation des Services.
                        <br></br>
                        Les CUSA font partie, avec les Conditions Générales de Vente (ci-après « <b>CGV</b> »)
                        du Contrat conclu entre le Client et TED.
                        <br></br>
                        TED se réserve le droit de faire évoluer ces CUSA à tout moment. En cas de modification,
                        les nouvelles CUSA seront portées à la connaissance du Client. Les CUSA applicables au Client sont
                        celles en vigueur au moment de la conclusion du Contrat entre TED et le Client ou du renouvellement
                        du Contrat.
                        <br></br>
                        Les mots commençant par une majuscule correspondent aux définitions données dans les CGV.
                    </p>







                    <h2 className="catch-phrase">3. Description des Services ALIZ</h2>

                    <p className="conditionsTerms-text">
                        TED met à la disposition du Client les Services, décrits ci-dessous,
                        qui pourront faire l’objet d’évolutions, notamment dans leurs contenus et fonctionnalités.
                        <br></br>
                        Le Client bénéficie de ces Services selon ses besoins et sous sa seule responsabilité. TED fournit les Services
                        selon une obligation de moyens.
                    </p>





                    <h3>3.1 Assistant virtuel « Gestion patientèle »</h3>

                    <p className="conditionsTerms-text">
                        L’assistant virtuel « Gestion patientèle » est un outil permettant au Client de gérer la
                        facturation de ses patients. L’assistant recueille des données, créé des factures qu’il envoie directement
                        aux patients du Client qu’il classe et stocke dans le Drive du Client.
                        Ces factures sont archivées mensuellement.
                        <br></br>
                        <br></br>
                        <b className="text-aliz">3.1.1 Options disponibles</b>
                        <br></br>
                        Ce Service est disponible en différentes options non cumulables :
                    </p>

                    <ul className="conditionsTerms-text">
                        <li>Le Pack Starter ;</li>
                        <li>Le Pack Business ;</li>
                        <li>Le Pack Premium.</li>
                    </ul>


                    <h3 className="text-aliz">- Le pack Starter</h3>

                    <p className="conditionsTerms-text">
                        Le pack Starter est un pack composé de cinquante (50) Crédits par mois, permettant de :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>Générer et envoyer des factures automatiquement ;</li>
                        <li>De gérer une base de données patient ;</li>
                        <li>De stocker et de classer des factures dans le Drive ;</li>
                        <li>De bénéficier de notification d’activités.</li>
                    </ul>

                    <p className="conditionsTerms-text">
                        Un volume de cinquante (50) factures par mois peut être envoyé dans un lieu d’exercice maximum.
                        <br></br>
                        Ce pack prévoit un Support client « standard », lequel correspond à une réponse par contact Chat et Mail,
                        cinq jours sur sept entre 9 heures et 17 heures.
                        <br></br>
                        En cas de dépassement du nombre de Crédits alloués, la facture supplémentaire est facturée quarante
                        (40)centimes d’euros l’unité.
                    </p>



                    <h3 className="text-aliz">- Le pack Business</h3>

                    <p className="conditionsTerms-text">
                        Le pack Business est un pack composé de cent (100) Crédits par mois qui,
                        en cumul des options disponibles dans le pack Starter, permet :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>D’avoir des factures personnalisées ;</li>
                        <li>D’historiciser et d’archiver les factures pour comptabilité ; </li>
                        <li>D’envoyer des pièces jointes personnalisées aux patients ;</li>
                        <li>De se connecter à l’agenda Doctolib et d’ajouter des informations dans la base Clients. </li>
                    </ul>

                    <p className="conditionsTerms-text">
                        Un volume de cent (100) factures par mois peut être envoyé dans un lieu d’exercice maximum.
                        <br></br>
                        Ce pack prévoit un Support client « prioritaire », lequel correspond à une réponse par contact Chat,
                        Mail et Téléphone, six jours sur sept entre 9 heures et 20 heures.
                        <br></br>
                        En cas de dépassement du nombre de Crédits alloués, la facture supplémentaire est facturée trente
                        (30) centimes d’euros l’unité.
                    </p>



                    <h3 className="text-aliz">- Le pack Premium</h3>

                    <p className="conditionsTerms-text">
                        Le pack Premium est un pack composé de deux cents (200) Crédits par mois qui,
                        en cumul des options disponibles dans le pack Starter et Business, permet :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>D’envoyer des mails personnalisés aux patients ;</li>
                        <li>De lire des agendas et ajouter automatiquement des informations dans la base du Client.</li>
                    </ul>

                    <p className="conditionsTerms-text">
                        Un volume de deux cents (200) factures par mois peut être envoyé sur trois (3) lieux.
                        <br></br>
                        Ce pack prévoit un Support client « prioritaire », lequel correspond à une réponse par contact Chat,
                        Mail et Téléphone, six jours sur sept entre 9 heures et 20 heures.
                        <br></br>
                        En cas de dépassement du nombre de Crédits alloués, la facture supplémentaire est facturée vingt
                        (20) centimes d’euros l’unité.
                        <br></br>
                        Les Crédits non utilisés dans le cadre de ces packs peuvent être réutilisés dans un délai de douze
                        (12) mois à compter de la Commande.
                    </p>




                    <h3 className="text-aliz">Essai gratuit</h3>

                    <p className="conditionsTerms-text">
                        Le Client a la possibilité d’essayer gratuitement l’assistant virtuel. Cet essai est prévu pour une
                        durée d’un mois. Le nombre de Crédits disponibles est illimité, dans la mesure du raisonnable.
                    </p>


                    <h4 className="text-aliz">3.1.2 Types d’Abonnement assistant virtuel « Gestion patientèle »</h4>
                    <p className="conditionsTerms-text">
                        Deux types d’Abonnement sont possibles, au choix du Client :
                    </p>


                    <h3 className="text-aliz">- Abonnement annuel</h3>
                    <p className="conditionsTerms-text">
                        L’Abonnement annuel démarre pour une durée de douze (12) mois à compter de la date de conclusion
                        du Contrat.
                        Les prix en fonction des packs sont les suivants :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>- Pack Starter : 13 euros TTC /mois</li>
                        <li>- Pack Business : 20 euros TTC /mois</li>
                        <li>- Pack Premium : 27 euros TTC/mois</li>
                    </ul>
                    <p className="conditionsTerms-text">
                        Le changement d’Abonnement vers un Abonnement mensuel ne peut avoir lieu qu’au renouvellement du Contrat,
                        sous réserve d’en informer le Prestataire par lettre recommandée avec accusé de réception reçue par le
                        Prestataire trente (30) jours avant la date de renouvellement du Contrat.
                    </p>


                    <h3 className="text-aliz">- Abonnement mensuel</h3>
                    <p className="conditionsTerms-text">
                        L’Abonnement mensuel démarre pour un mois glissant à compter de la date de conclusion du Contrat.
                        Il est possible pour le Client de souscrire un Abonnement annuel en suivant la procédure de commande
                        décrite dans les CGV. Dans ce cas, la modification sera appliquée à compter de la date de renouvellement
                        du Contrat.
                        <br></br>
                        Si la modification intervient pendant un mois en cours, les Frais d’abonnement de l’Abonnement
                        seront rétrocédés au prorata selon la date de conclusion de l’Abonnement annuel.
                        <br></br>
                        Les prix en fonction des packs sont les suivants :

                    </p>
                    <ul className="conditionsTerms-text">
                        <li>- Pack Starter : 19 euros TTC /mois</li>
                        <li>- Pack Business : 29 euros TTC /mois</li>
                        <li>- Pack Premium : 39 euros TTC/mois</li>
                    </ul>
                    <p className="conditionsTerms-text">
                        Aucuns frais ne seront facturés du fait de l’inutilisation du Service.
                        <br></br>
                        <b className="text-aliz">Dépassements des Crédits</b>
                        <br></br>
                        Le Client sera averti par notification électronique et/ou par mail lorsque le nombre de
                        Crédits restants sera inférieur à 50%, 75 % puis 90% de consommation des crédits.
                        <br></br>
                        En cas de dépassement du nombre de Crédits initialement alloués au regard du pack et de
                        l’Abonnement souscrits :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>
                            Les fonctionnalités demeureront disponibles et l’envoi des factures s’effectuera automatiquement,
                            sans action spécifique du Client ;
                        </li>
                        <li>
                            Le Client sera immédiatement facturé des frais y afférant lors de la Période de facturation.
                            Ces frais seront facturés en fonction de la tranche tarifaire correspondant au nombre de
                            factures générées et envoyées par mail aux patients du Client.
                        </li>
                    </ul>







                    <h3>3.2 Assistant virtuel « Déclaration TVA »</h3>

                    <p className="conditionsTerms-text">
                        Le Service « Déclaration TVA » est un assistant permettant au Client de gérer les déclarations de
                        TVA et de les saisir sur la plateforme impot.gouv.fr. Pour ce faire, l’assistant recueille les
                        données définies, crée des déclarations et les transmet sur la plateforme.
                        <br></br>
                        <b className="text-aliz">3.2.1 Options disponibles</b>
                        <br></br>
                        Ce Service est disponible en différentes options non cumulables :
                    </p>

                    <ul className="conditionsTerms-text">
                        <li>Le Pack Starter ;</li>
                        <li>Le Pack Business ;</li>
                        <li>Le Pack Premium.</li>
                    </ul>



                    <h3 className="text-aliz">- Le pack Starter</h3>

                    <p className="conditionsTerms-text">
                        Le pack Starter est un pack composé de cinquante (50) Crédits par mois, permettant de :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>Faire une déclaration automatique de TVA sur impôts.gouv ;</li>
                        <li>
                            Stocker la déclaration de TVA, de l’accusé de réception et la
                            validation de paiement dans votre espace de travail (Drive) en format PDF ;
                        </li>
                        <li>Remettre un rapport détaillé par e-mail de l’activité de votre assistant Aliz ;</li>
                        <li>Etablir une historisation mensuelle des déclarations TVA réalisées ;</li>
                    </ul>

                    <p className="conditionsTerms-text">
                        La fréquence d’envois des déclarations TVA est de dix (10) par mois.
                        <br></br>
                        L’utilisateur dispose d’une (1) licence. L’ajout d’un (1) utilisateur additionnel sur la licence
                        s’élève à vingt-cinq (25) euros par mois.
                        <br></br>
                        En cas de dépassement du nombre de Crédits alloués, la déclaration à l’unité supplémentaire est facturée
                        à zéro virgule quatre-vingt-dix-huit (0,98) euros.
                        <br></br>
                        Ce pack prévoit un Support client « standard », lequel correspond à une réponse par contact Chat et Mail,
                        cinq jours sur sept entre 9 heures et 17 heures.
                    </p>



                    <h3 className="text-aliz">- Le pack Business</h3>

                    <ul className="conditionsTerms-text">
                        <li>
                            Le pack Business est un pack composé de cent (100) Crédits qui, en cumul des options disponibles
                            dans le pack Starter, permet de : Etablir un contrôle de cohérence automatisé de la TVA déclarée.
                        </li>
                    </ul>


                    <p className="conditionsTerms-text">
                        La fréquence d’envois des déclarations TVA est de dix (10) par mois.
                        L’utilisateur dispose d’une (1) licence. L’ajout d’un (1) utilisateur additionnel sur la licence
                        s’élève à vingt (20) euros par mois.
                        <br></br>
                        En cas de dépassement du nombre de Crédits alloués, la déclaration à l’unité supplémentaire est facturée
                        à zéro virgule soixante-dix-neuf (0,79) euros.
                        <br></br>
                        Ce pack prévoit un Support client « prioritaire », lequel correspond à une réponse par contact Chat,
                        Mail et Téléphone, six jours sur sept entre 9 heures et 20 heures.

                    </p>



                    <h3 className="text-aliz">- Le pack Premium</h3>

                    <p className="conditionsTerms-text">
                        Le pack Premium est un pack composé de deux fois cent cinquante (150x2) Crédits qui, en cumul des
                        options disponibles dans les packs Starter et Business permet de :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>Envoyer un email automatique pour demander aux clients les pièces comptables.</li>
                    </ul>

                    <p className="conditionsTerms-text">
                        La fréquence d’envois des déclarations TVA est illimitée.
                        <br></br>
                        L’utilisateur dispose de deux (2) licences. L’ajout d’un (1) utilisateur additionnel sur la licence
                        s’élève à quinze (15) euros par mois.
                        <br></br>
                        En cas de dépassement du nombre de Crédits alloués, la déclaration TVA à l’unité supplémentaire est
                        facturée à zéro virgule soixante-treize (0,73) euros.
                        <br></br>
                        Ce pack prévoit un Support client « prioritaire », lequel correspond à une réponse par contact Chat,
                        Mail et Téléphone, six jours sur sept entre 9 heures et 20 heures.
                    </p>





                    <h3 className="text-aliz">Essai gratuit</h3>

                    <p className="conditionsTerms-text">
                        Le Client a la possibilité d’essayer gratuitement l’assistant virtuel. Cet essai est prévu pour une
                        durée d’un mois. Le nombre de Crédits disponibles est illimité, dans la mesure du raisonnable.
                    </p>


                    <h4 className="text-aliz">3.2.2 Types d’Abonnement assistant virtuel « Déclaration TVA »</h4>
                    <p className="conditionsTerms-text">
                        Deux types d’Abonnement sont possibles, au choix du Client :
                    </p>


                    <h3 className="text-aliz">- Abonnement annuel</h3>
                    <p className="conditionsTerms-text">
                        L’Abonnement annuel démarre pour une durée de douze (12) mois à compter de la date de conclusion
                        du Contrat.
                        Les prix en fonction des packs sont les suivants :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>- Pack Starter : 39 euros TTC /mois</li>
                        <li>- Pack Business : 59 euros TTC /mois</li>
                        <li>- Pack Premium : 89 euros TTC/mois</li>
                    </ul>
                    <p className="conditionsTerms-text">
                        Le changement d’Abonnement vers un Abonnement mensuel ne peut avoir lieu qu’au renouvellement du Contrat,
                        sous réserve d’en informer le Prestataire par lettre recommandée avec accusé de réception reçue par le
                        Prestataire trente (30) jours avant la date de renouvellement du Contrat.
                    </p>


                    <h3 className="text-aliz">- Abonnement mensuel</h3>
                    <p className="conditionsTerms-text">
                        L’Abonnement mensuel démarre pour un mois glissant à compter de la date de conclusion du Contrat.
                        Il est possible pour le Client de souscrire un Abonnement annuel en suivant la procédure de commande
                        décrite dans les CGV. Dans ce cas, la modification sera appliquée à compter de la date de renouvellement
                        du Contrat.
                        <br></br>
                        Si la modification intervient pendant un mois en cours, les Frais d’abonnement de l’Abonnement
                        seront rétrocédés au prorata selon la date de conclusion de l’Abonnement annuel.
                        <br></br>
                        Les prix en fonction des packs sont les suivants :

                    </p>
                    <ul className="conditionsTerms-text">
                        <li>- Pack Starter : 49 euros TTC /mois</li>
                        <li>- Pack Business : 79 euros TTC /mois</li>
                        <li>- Pack Premium : 109 euros TTC/mois</li>
                    </ul>
                    <p className="conditionsTerms-text">
                        Il est toutefois possible pour le Client de souscrire un Abonnement annuel en suivant la procédure
                        de commande décrite dans les CGV. Dans ce cas, la modification sera appliquée à la date de renouvellement du
                        Contrat.
                        <br></br>
                        Si la modification intervient pendant un mois en cours, les frais d’Abonnement de l’Abonnement seront
                        rétrocédés au prorata selon la date de conclusion de l’abonnement annuel.
                        <br></br>
                        Aucuns frais ne seront facturés du fait de l’inutilisation du Service.
                    </p>


                    <h4 className="text-aliz">3.2.3 Dépassements des Crédits</h4>

                    <p className="conditionsTerms-text">
                        Le Client sera averti par notification électronique et/ou par mail lorsque le nombre de
                        Crédits restants sera inférieur à 50%, 75 % et 90% de consommation des crédits.
                        <br></br>
                        En cas de dépassement du nombre de Crédits initialement alloués au regard du pack et de
                        l’Abonnement souscrits :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>
                            Les fonctionnalités demeureront disponibles et l’envoi des déclarations s’effectuera
                            automatiquement, sans action spécifique du Client ;
                        </li>
                        <li>
                            Le Client sera immédiatement facturé des frais y afférant lors de la Période de facturation.
                            Ces frais seront facturés en fonction de la tranche tarifaire correspondant au nombre de
                            déclarations TVA réalisées sur impot.gouv.
                        </li>
                    </ul>









                    <h3>3.3 Assistant virtuel « Gestion de rendez-vous »</h3>

                    <p className="conditionsTerms-text">
                        Le Service « Gestion de rendez-vous » est un service permettant d’automatiser la gestion des agendas
                        électronique, en se connectant directement sur les outils de prise de rendez-vous. La fourniture du
                        Service basé sur un modèle de plans de facturation et Abonnement annuel ou mensuel ainsi que des
                        packs avec différentes options :
                    </p>



                    <h4 className="text-aliz">3.3.1 Options disponibles</h4>
                    <p className="conditionsTerms-text">
                        Ce Service est disponible en différentes options non cumulables :
                    </p>

                    <ul className="conditionsTerms-text">
                        <li>Le Pack Starter ;</li>
                        <li>Le Pack Business ;</li>
                        <li>Le Pack Premium.</li>
                    </ul>



                    <h3 className="text-aliz">- Le pack Starter</h3>

                    <p className="conditionsTerms-text">
                        Le pack Starter permet de :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>Intégrer Google Meet</li>
                        <li>Ajouter AlizCalendar au site web</li>
                        <li>Suivre les rendez-vous avec notifications</li>
                        <li>Être notifié de l’activité par l’assistant ALIZ</li>
                    </ul>
                    <p className="conditionsTerms-text">
                        L’utilisateur dispose d’un (1) calendrier. Le volume de rendez-vous par mois est illimité.
                        <br></br>
                        Ce pack prévoit un Support client « Standard », lequel correspond à une réponse par contact
                        Chat et Mail, cinq jours sur sept entre 9 heures et 17 heures.
                    </p>


                    <h3 className="text-aliz">- Le pack Business</h3>

                    <p className="conditionsTerms-text">
                        Le pack Business est un pack qui, en cumul des options disponibles dans le pack Starter, permet de :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>Créer différents types d’évènements de manière illimitée</li>
                        <li>Créer des champs personnalisables dans le formulaire de manière illimitée</li>
                    </ul>

                    <p className="conditionsTerms-text">
                        L’utilisateur dispose de trois (3) calendriers simultanés. Le volume de rendez-vous par
                        mois est illimité.
                        <br></br>
                        Ce pack prévoit un Support client « Prioritaire », lequel correspond à une réponse par contact
                        Chat, Mail et Téléphone, six jours sur sept entre 9 heures et 20 heures.
                    </p>



                    <h3 className="text-aliz">- Le pack Premium</h3>

                    <p className="conditionsTerms-text">
                        Le pack Business est un pack qui, en cumul des options disponibles dans le pack Starter, permet de :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>Retirer les éléments publicitaires Aliz </li>
                    </ul>

                    <p className="conditionsTerms-text">
                        L’utilisateur dispose de six (6) calendriers simultanés.
                        Le volume de rendez-vous par mois est illimité.
                        <br></br>
                        Ce pack prévoit un Support client « Prioritaire », lequel correspond à une réponse par contact Chat,
                        Mail et Téléphone, six jours sur sept entre 9 heures et 20 heures.
                    </p>



                    <h4 className="text-aliz">3.3.2 Types d’Abonnement assistant virtuel « Gestion de rendez-vous »</h4>
                    <p className="conditionsTerms-text">Deux types d’Abonnement sont possibles, au choix du Client :</p>





                    <h3 className="text-aliz">- Abonnement annuel</h3>
                    <p className="conditionsTerms-text">
                        L’Abonnement annuel démarre pour une durée de 12 mois à compter de la date de conclusion du Contrat.
                        <br></br>
                        Les prix en fonction des packs sont les suivants :

                    </p>
                    <ul className="conditionsTerms-text">
                        <li>- Pack Starter : Gratuit</li>
                        <li>- Pack Business : 4 euros TTC /mois</li>
                        <li>- Pack Premium : 9 euros TTC/mois</li>
                    </ul>
                    <p className="conditionsTerms-text">
                        Le changement d’Abonnement pour un Abonnement mensuel ne peut avoir lieu qu’au renouvellement
                        du Contrat, sous réserve d’en informer le Prestataire par lettre recommandée avec accusé de
                        réception reçue par le Prestataire trente (30) jours avant la date de renouvellement du Contrat.
                    </p>


                    <h3 className="text-aliz">- Abonnement mensuel</h3>
                    <p className="conditionsTerms-text">
                        L’abonnement mensuel démarre pour un mois glissant à compter de la conclusion du Contrat.
                        <br></br>
                        Les prix en fonction des packs sont les suivants :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>- Pack Starter : Gratuit</li>
                        <li>- Pack Business : 7 euros TTC /mois</li>
                        <li>- Pack Premium : 12 euros TTC/mois</li>
                    </ul>
                    <p className="conditionsTerms-text">
                        Il est toutefois possible pour le Client de souscrire un Abonnement annuel en suivant
                        la procédure de commande décrite dans les CGV.
                        Dans ce cas, la modification sera appliquée à la date de renouvellement du Contrat.
                        <br></br>
                        Si la modification intervient pendant un mois en cours, les frais d’abonnement de l’Abonnement
                        seront rétrocédés au prorata selon la date de conclusion de l’Abonnement annuel.
                    </p>








                    <h2 className="catch-phrase">4. ModalitÉs d’utilisation des Services</h2>

                    <h3>3.1 Accès aux Services </h3>
                    <p className="conditionsTerms-text">
                        L’utilisation des Services est limitée à une utilisation strictement professionnelle,
                        conformément aux CGV.
                        <br></br>
                        Le Client et les Utilisateurs reconnaissent disposer de la compétence, des matériels et
                        des moyens nécessaires pour utiliser les Services et s’engagent à utiliser un matériel
                        exempt de virus ou de logiciel malveillant.
                        <br></br>
                        Pour souscrire aux Services, le Client doit créer un Compte Client.
                        <br></br>
                        Tout au long de la Période d'abonnement, TED donne accès, pour certains services, aux Utilisateurs
                        du Client au Service d'abonnement tel que décrit dans les présentes Conditions ainsi que dans la Commande
                        applicable. TED peut également donner accès aux Clients à ses Outils gratuits à tout moment en les
                        activant dans son Compte Client Aliz.
                        <br></br>
                        Si le Client souhaite donner accès à son Compte Client à plusieurs Utilisateurs (possible uniquement pour
                        l’Assistant de déclaration TVA), il en informe TED qui pourra alors ouvrir des accès Utilisateurs.
                        Chaque accès Utilisateur est associé au Client. La liste des Utilisateurs peut être modifiée à
                        tout moment et sans frais supplémentaires, sur demande écrite (e-mail ou courrier) du Client,
                        sous réserve de respecter le nombre minimal d’Utilisateurs éventuellement défini entre les Parties.
                        <br></br>
                        Pour que la demande de modification soit valable, il convient de préciser le nom et l’e-mail
                        des Utilisateurs concernés.
                        <br></br>
                        Les identifiants de connexion sont strictement personnels et doivent être conservés secrets par
                        le Client et les Utilisateurs, sous la responsabilité du Client. Toute utilisation des Services
                        à partir d’un Compte Client est réputée émaner du Client ou de ses Utilisateurs. TED n’a pas pour
                        obligation de s’assurer de l’identité des personnes ayant accédé aux Services.
                        <br></br>
                        Le Client s’oblige à mettre en œuvre tout ce qui est raisonnable pour empêcher tout accès ou
                        utilisation non autorisée et à informer immédiatement TED de toute utilisation non autorisée de
                        ses identifiants, mots de passe et Compte Client à l’adresse support@aliz.io.
                        <br></br>
                        Le Client ne doit pas utiliser le Service d'abonnement s'il lui est légalement interdit de recevoir
                        ou d'utiliser ledit Service en vertu des lois du pays dans lequel il réside, ou à partir duquel il
                        accède audit Service ou utilise ledit Service.
                    </p>





                    <h3>3.2 Utilisation des Services  </h3>
                    <p className="conditionsTerms-text">
                        Le Client et les Utilisateurs s’engagent à utiliser les Services avec loyauté et bonne foi.
                        Ils reconnaissent à ce titre qu’ils sont soumis aux obligations suivantes :
                    </p>

                    <ul className="conditionsTerms-text">
                        <li>Le Client est l’unique interlocuteur de TED ; </li>
                        <li>
                            Le Client et les utilisateurs procèdent eux-mêmes, dans les conditions et selon les fonctionnalités
                            qui leur sont proposées, à l’installation des Services sous leur seule responsabilité ;
                        </li>
                        <li>Le Client s’engage à actualiser et à maintenir son Compte Client à jour ;</li>
                        <li>
                            Le Client s’engage à collaborer avec TED dans la mesure requise pour assurer le bon fonctionnement
                            des Services, sans que son intervention ne puisse être qualifiée d’immixtion ;
                        </li>
                        <li>
                            Ne pas détourner la finalité des Services ALIZ pour commettre des crimes,
                            délits ou contraventions réprimées par le Code pénal ou toute autre loi ;
                        </li>
                        <li>
                            Se conformer à toutes les lois, règlements en vigueur encadrant notamment le Règlement
                            (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection
                            des personnes physiques à l'égard du traitement des données à caractère personnel et à la
                            libre circulation de ces données ;
                        </li>
                        <li>
                            Ne pas chercher à porter atteinte au sens des articles 323-1 et suivants du Code pénal aux systèmes
                            de traitements automatisés de données mis en œuvre pour l’exploitation des Services ;
                        </li>
                        <li>
                            Ne pas violer ou tenter de violer la sécurité ou l'intégrité des Services, de communiquer toute
                            information trompeuse, d'utiliser les informations à des fins illicites.
                        </li>
                        <li>Ne pas entraver le bon fonctionnement des Services. </li>
                    </ul>





                    <h3>3.3 Obligations de TED </h3>

                    <p className="conditionsTerms-text">
                        Le Client pourra utiliser le Service 24 heures sur 24, 7 jours sur 7, y compris les dimanches
                        et jours fériés, hors périodes de maintenance. Toutefois, TED peut, à sa seule discrétion,
                        suspendre interrompre, ou interdire l’accès à tout ou partie des Services. TED ne pourra pas être
                        tenu pour responsable en cas d’indisponibilité des Services vis-à-vis de l’Utilisateur,
                        pour quelque cause que ce soit.
                        <br></br>
                        TED peut interrompre, à sa discrétion, l’accès aux Services pour en assurer la maintenance.
                        Dans ce cas, TED en informera le Client cinq (5) jours avant la date prévue de l’interruption.
                        <br></br>
                        Dans le cadre des Services, TED s’engage à fournir au Client :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>
                            I. Un service de support technique, accessible de 9 heures à 18 heures, du lundi au vendredi,
                            sauf jours fériés français ;
                        </li>
                        <li>
                            II. Un accompagnement en ligne de chaque nouvel Utilisateur ;
                        </li>
                        <li>III. L’assistance à l’utilisation par téléphone ou par e-mail à l’adresse support@aliz.io ;</li>
                        <li>
                            IV. Une maintenance corrective. TED s’engage, sans surcoût, à faire bénéficier au Client de l’ensemble des correctifs qu’il met en œuvre afin d’assurer la bonne utilisation du Service.
                        </li>
                        <li>
                            V. Une maintenance évolutive. TED s’engage à faire bénéficier le Client de l’ensemble des mises à jour du Service.
                        </li>
                    </ul>



                    <p className="conditionsTerms-text">
                        <b>Niveau de Service.</b> TED s’engage à traiter les requêtes effectuées par le Client au cours
                        d’un (1) mois calendaire donné dans un délai maximum de 48h à compter de la saisie d’une information
                        partagée via son Compte Client Aliz.
                        <br></br>
                        Le Client pourra utiliser le Service 24 heures sur 24, 7 jours sur 7, y compris les
                        dimanches et jours fériés, hors périodes de maintenance.
                        <br></br>
                        Toutefois, TED peut interrompre, à sa discrétion, l’accès aux Services pour en assurer la maintenance.
                        Dans ce cas, TED en informera le Client cinq (5) jours avant la date prévue de l’interruption.
                        <br></br>
                        <b>Sécurité.</b> TED ne saurait être tenu pour responsable vis-à-vis du Client ou de l’Utilisateur
                        des atteintes à la sécurité informatique pouvant causer des dommages à ses équipements,
                        données, ou en cas d’intrusion de tiers malgré les mesures de sécurité mises en place.
                    </p>







                    <h2 className="catch-phrase">5. Garanties</h2>

                    <p className="conditionsTerms-text">
                        TED ne garantit pas que le Service, ses mises à Jour et ses nouvelles versions seront dépourvus d’Anomalies,
                        mais s’engage à corriger les Anomalies dans le cadre de la maintenance corrective. TED ne garantit pas
                        que le Service est compatible avec d’autres systèmes ou configurations que ceux expressément approuvés
                        par TED et il appartient au Client de s’assurer qu’il répond à ceux-ci, ou encore que le Service répond
                        à ses besoins et contraintes.
                        <br></br>
                        Le Client reconnaît que le Prestataire ne contrôle pas le transfert des données par le biais de différents
                        moyens de communication (dont Internet) et que le Service peut être limité, retardé, ou sujet à d’autres
                        problèmes inhérents à l’utilisation de tels moyens de communication.
                    </p>
                </div>
            </div>

        </div >
    )
}
