import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div id="footer">
            <ul>
                <li><Link to="/politique-de-confidentialite">Politique de confidentialité</Link></li>
                <li><Link to="/conditions-et-service">Conditions et Service</Link></li>
                <li><Link to="/support">Support</Link></li>
            </ul>
        </div>
    )
}
