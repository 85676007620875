import React, { useEffect, useState } from 'react'

export default function ContactUs() {
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [name, setName] = useState("")
    const [response, setResponse] = useState([])

    const endPoint = process.env.REACT_APP_API_ALIZ_ENDPOINT

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "email": email,
            "phone": phone,
            "name": name
        })
    };

    const contactSupport = () => {
        fetch(endPoint + "alizFacturation/new", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.errors) {
                        setResponse(result.errors)
                    } else {
                        setResponse(result)

                    }
                    console.log(result)
                },
                (error) => {
                    setResponse(error)
                }
            )
    }

    useEffect(() => {
        if (response) {

        }
    }, [response])

    return (
        <div id="contactUs">
            <h2>Nous contacter</h2>

            <div id="all-input">
                
                {response.response === "success" && <div className="popup-success">Votre demande de contact à bien été prise en compte</div>}

                <div className="input-block">
                    <label htmlFor="email">Email</label>
                    <input id="email" type="email" placeholder="peterparker@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {response.email && <p className="error-msg">{response.email}</p>}
                </div>

                <div className="input-block">
                    <label>Téléphone</label>
                    <input id="phone" type="tel" placeholder="0711223344" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    {response.phone && <p className="error-msg">{response.phone}</p>}
                </div>

                <div className="input-block">
                    <label>Nom</label>
                    <input id="name" type="text" placeholder="Peter Parker" value={name} onChange={(e) => setName(e.target.value)} />
                    {response.name && <p className="error-msg">{response.name}</p>}
                </div>

                <div className="input-block">
                    <button onClick={contactSupport} className="btn-alizFull">Envoyer</button>
                </div>
            </div>

            <div className="circle" id="circle-1"></div>
            <div className="circle" id="circle-2"></div>
            <div className="circle" id="circle-3"></div>

        </div>
    )
}
