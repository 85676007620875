import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Presentation from './Presentation'
import ContactUs from './ContactUs'

function Home() {
    return (
        <HelmetProvider>
            <Helmet prioritizeSeoTags>
                <title>Aliz - Vérification de données légales d'entreprise</title>
                <link rel="notImportant" href="https://calendar.aliz.io" />
                <meta name="description" value="Votre assistant virtuel de vérification et de nettoyage de données légales d'entreprise" />
                <link rel="canonical" href="https://legal-data.aliz.io" />
                <meta property="og:title" content="" />
            </Helmet>

            <div id="home">
                <Presentation />

                <div id="iframe-block">
                    <h2>Pour bien démarrer </h2>
                    <iframe src="https://www.youtube.com/embed/yybnis2V1mk" title="Tutoriel AlizFacturation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <ContactUs />


            </div>

        </HelmetProvider>
    )
}

export default Home