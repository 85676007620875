import React from 'react'

export default function PrivacyPolicy() {
    return (
        <div className="conditionsTerms container-fluid">

            <div className="row justify-content-center">
                <div className="col-10 col-md-10 col-lg-7 col-xl-6">

                    <h1>Politique de Confidentialité</h1>
                    <h2 className="catch-phrase">Mise à jour : 09/07/2021</h2>



                    <h2 className="catch-phrase">Qui sommes-nous ?</h2>

                    <p className="conditionsTerms-text">
                        La société TED, qui détient la marque Aliz et Aliz Facturation(« <b>TED</b> » ou « <b>nous</b> »),
                        édite l’assistant virtuel de gestion patientèle Aliz (ci-après l’ « <b>assistant Aliz</b> »)
                        et les sites internet accessibles via les URL suivant : <a href="https://aliz.io">aliz.io</a>, <a href="https://calendar.aliz.io">calendar.aliz.io</a> et <a href="https://facturation.aliz.io">facturation.aliz.io</a>

                        (ci-après les « <b>Sites internet</b> »).
                        Dans ce cadre, TED est susceptible de traiter des données à caractère personnel collectées auprès des utilisateurs
                        de l’assistant Aliz dans le cadre de la fourniture des Sites internet et de l’assistant Aliz,
                        en qualité de responsable de traitement des données.
                        <br></br>
                        TED est également sous-traitant des données de patients collectées par les utilisateurs de l’assistant Aliz dans
                        le cadre de leurs activités professionnelles.
                        <br></br>
                        TED place la protection des données à caractère personnel au cœur de ses préoccupations et cette Politique de confidentialité
                        a pour objectif de vous informer sur nos pratiques concernant la collecte, l’utilisation et le partage des données que nous traitons.
                        <br></br>
                        <b>À noter :</b> la Politique de confidentialité de TED ne concerne pas les traitements mis
                        en œuvre par les professionnels dans le traitement de leur clientèle ou de leur patientèle personnelle,
                        qui demeurent responsables de traitement de ces données.
                    </p>







                    <h2 className="catch-phrase">Nous contacter</h2>

                    <p className="conditionsTerms-text">
                        Si vous souhaitez nous contacter, vous pouvez :
                    </p>
                    <ul className="conditionsTerms-text">
                        <li>nous envoyer un courriel à l’adresse : <u>dpo@aliz.io</u>, <u>contact@aliz.io</u> ou <u>support@aliz.io</u>.</li>
                        <li>nous envoyer un courrier à l’adresse : 59 RUE DE PONTHIEU – bureau 562 , 75008 PARIS</li>
                    </ul>

                    <p className="conditionsTerms-text">
                        Nous vous répondrons le plus rapidement possible !
                    </p>






                    <h2 className="catch-phrase">Vous êtes utilisateur des Sites internet</h2>

                    <p className="conditionsTerms-text">
                        <u className="text-aliz">Quelles sont les données traitées et pour quelles finalités ?</u>
                        <br></br>
                        Le traitement de données a pour objet la gestion des Sites internet édités par TED.
                        <br></br>
                        Les données traitées par TED sont collectées directement auprès de vous et ne sont utilisées que pour les finalités décrites ci-dessous.
                        <br></br>
                        Les données demandées lors de la création du compte sont obligatoires.
                        À défaut, vous ne pourrez pas vous connecter.
                        Le cas échéant, le caractère obligatoire ou facultatif des données collectées et les éventuelles conséquences d'un défaut
                        de réponse sont précisés au moment de leur collecte.
                        <br></br>
                        TED, en tant que responsable de traitement de vos données, les utilise pour :
                    </p>

                    <ul className="conditionsTerms-text">
                        <li><b>Intérêt légitime de TED : </b></li>
                        <ul>
                            <li>administration technique et amélioration des Sites internet ; </li>
                            <li>création d’un compte Aliz ; </li>
                            <li>réponse aux demandes et formulaires de contact ; </li>
                            <li>réalisation de statistiques d’audience et d’utilisation des services en ligne ; </li>
                            <li>assurer la sécurité des Sites internet ; </li>
                            <li>garantir la défense de nos droits. </li>
                        </ul>
                        <li><b>Obligations légales :</b></li>
                        <ul>
                            <li>garantir l’exercice de vos droits. </li>
                        </ul>
                        <li><b>Consentement : </b></li>
                        <ul>
                            <li>envoi de communications commerciales ou générales sur les Services Aliz. </li>
                        </ul>
                    </ul>

                    <p className="conditionsTerms-text">
                        Nous utilisons des cookies sur les Sites internet, toutes les informations à ce sujet sont dans la
                        <a href="https://aliz.io/conditions-generales/politique-utilisation-des-cookies">Politique de gestion des cookies</a>.
                    </p>







                    <h2 className="catch-phrase">Destinataires de vos données et transferts</h2>

                    <p className="conditionsTerms-text">
                        Nous traitons vos données pour notre propre compte et de manière confidentielle.
                        Ainsi, vos données sont susceptibles d’être transmises à nos services internes autorisés à en
                        connaître au regard de leurs fonctions et à nos prestataires autorisés.
                        <br></br>
                        Les destinataires externes sont notamment nos prestataires ou services support.
                        Dans certains cas prévus par la loi, vos données pourront être transmises à des tiers habilités légalement
                        à y accéder sur requête spécifique (autorité judiciaire, autorité administrative, etc.).
                        Nous pourrons aussi être amenés à communiquer vos données à des tiers si une telle mesure est nécessaire pour
                        protéger et/ou défendre nos droits, pour faire respecter les présentes dispositions.
                        <br></br>
                        Nous ne transmettons pas vos données à des tiers non autorisés.
                        <br></br>
                        Les données du service sont hébergées en France.
                        Vos données ne sont transférées hors de l’Espace économique européen qu’avec la mise en place de garanties appropriées,
                        conformément au chapitre V du RGPD.
                        <br></br>
                        Si vous ne souhaitez plus recevoir nos communications, vous pourrez vous désinscrire en cliquant sur le lien de désengagement
                        au bas du courrier électronique ou en nous envoyant un courrier électronique à dpo@aliz.io.
                    </p>









                    <h2 className="catch-phrase">Vous êtes professionnel, vous utilisez l’assistant Aliz pour gérer votre facturation </h2>

                    <p className="conditionsTerms-text">
                        <u className="text-aliz">Quelles sont les données traitées et pour quelles finalités ?</u>
                        <br></br>
                        Le traitement de données a pour objet la fourniture de l’assistant Aliz aux professionnels.
                        <br></br>
                        Les données traitées par Aliz sont collectées directement auprès de vous et ne sont utilisées que
                        pour les finalités décrites ci-dessous.
                        <br></br>
                        Les données demandées lors de la création du compte et de l’inscription pour sont obligatoires.
                        À défaut, vous ne pourrez pas utiliser l’assistant Aliz. Le cas échéant, le caractère obligatoire ou facultatif
                        des données collectées et les éventuelles conséquences d'un défaut de réponse sont précisés au moment de leur collecte.
                        <br></br>
                        Aliz, en tant que responsable de traitement de vos données, les utilise pour :
                    </p>

                    <ul className="conditionsTerms-text">
                        <li><b>Exécution des mesures précontractuelles et contractuelles : </b></li>
                        <ul>
                            <li>répondre à vos demandes dans le cadre de l’utilisation de l’assistant Aliz ; </li>
                            <li>création de votre assistant virtuel Aliz ; </li>
                            <li>mise à disposition de l’assistant Aliz et gestion de son bon fonctionnement ;</li>
                            <li>envoi des factures à vos patients et traitement de vos demandes ; </li>
                            <li>traitement des paiements et transactions ;</li>
                            <li>gestion de la facturation ; </li>
                        </ul>

                        <li><b>Intérêt légitime de Aliz : </b></li>
                        <ul>
                            <li>amélioration des services et de l’assistant Aliz ;</li>
                            <li>réalisation de statistiques ; </li>
                            <li>commercialisation de nos services ou de nos programmes de partenariat ; </li>
                            <li>envoi des communications sur nos offres ; </li>
                            <li>assurer la sécurité de l’assistant Aliz et de TED ;</li>
                            <li>fourniture, soutien et amélioration des Sites internet, de nos programmes partenaires ou des communautés ;</li>
                            <li>garantir la défense de nos droits.</li>
                        </ul>

                        <li><b>Obligations légales : </b></li>
                        <ul>
                            <li>garantir l’exercice de vos droits ; </li>
                            <li>gestion de nos obligations comptables. </li>
                        </ul>
                    </ul>


                    <p className="conditionsTerms-text">
                        Si vous ne souhaitez plus recevoir nos communications sur nos offres,
                        vous pourrez vous désinscrire en cliquant sur le lien de désengagement au bas du courrier électronique
                        ou en nous envoyant un courrier électronique à <u>dpo@aliz.io</u>.
                    </p>


                    <p className="conditionsTerms-text">
                        <u className="text-aliz">Destinataires de vos données et transfert </u>
                        <br></br>
                        Nous traitons vos données pour notre propre compte et de manière confidentielle.
                        Ainsi, vos données sont susceptibles d’être transmises à nos services internes autorisés à en
                        connaître au regard de leurs fonctions et à nos prestataires autorisés.
                        <br></br>
                        Les destinataires externes sont notamment nos prestataires ou services support
                        (sous-traitants, informatique, hébergeur de l’assistant Aliz).
                        Dans certains cas prévus par la loi, vos données pourront être transmises à des tiers
                        habilités légalement à y accéder sur requête spécifique : autorité judiciaire, autorité administrative, etc.
                        Nous pourrons aussi être amenés à communiquer vos Données à des tiers si une telle mesure est nécessaire pour
                        protéger et/ou défendre nos droits, pour faire respecter les présentes dispositions.
                        <br></br>
                        Nous ne transmettons pas vos données à des tiers non autorisés.
                        <br></br>
                        Les données du service sont hébergées en France.
                        Les données peuvent être transférées dans des pays hors de l’Espace économique européen dans les cas suivants :
                    </p>

                    <ul className="conditionsTerms-text">
                        <li>Données de suivi de la relation commerciale, de facturation et communication</li>
                        <li>Consultation des emails transmis au service et événements de calendrier de l’utilisateur (supervision et revue qualité)</li>
                    </ul>


                    <p className="conditionsTerms-text">
                        Dans ce cas, vos données sont transférées qu’avec la mise en place de garanties appropriées,
                        conformément au chapitre V du RGPD.
                        <br></br>
                        Pour en savoir plus sur les transferts vers un pays tiers ou les mesures de sécurité spécifiques applicables,
                        vous pouvez nous contacter.
                    </p>




                    <h2 className="catch-phrase">Vous êtes patient d’un professionnel utilisant l’assistant Aliz </h2>

                    <p className="conditionsTerms-text">
                        <u className="text-aliz">Quelles sont les données traitées et pour quelles finalités ?</u>
                        <br></br>
                        Aliz traite vos données en qualité de sous-traitant,
                        le professionnel qui utilise l’assistant Aliz est responsable de traitement.
                        <br></br>
                        Aliz ne traite que les données strictement nécessaires pour la facturation des prestations dont
                        vous avez bénéficié auprès du professionnel et conformément aux instructions du professionnel responsable de traitement.
                        Les données sont collectées directement auprès des professionnels utilisant l’assistant Aliz.
                        <br></br>
                        Vous êtes informés des finalités et des modalités du traitement de vos données par le professionnel.

                        <br></br>
                        <u className="text-aliz">Destinataires de vos données et transfert </u>
                        <br></br>
                        Nous traitons vos données pour notre propre compte et de manière confidentielle.
                        Ainsi, vos données sont susceptibles d’être transmises à nos services internes autorisés à en connaître au regard
                        de leurs fonctions et à nos prestataires autorisés, dans le respect du secret médical.
                        <br></br>

                        Les données peuvent être transférées dans des pays hors de l’Espace économique européen dans le cadre
                        des consultations des courriels transmis au service et événements de calendrier de l’utilisateur
                        (supervision et revue qualité), en fonction des outils utilisés par le professionnel.
                        <br></br>
                        Vos données sont transférées qu’avec la mise en place de garanties appropriées, conformément au chapitre V du RGPD.

                        <br></br>
                        <u className="text-aliz">Exercice des droits</u>
                        <br></br>
                        Pour exercer vos droits en tant que patient, contactez directement le professionnel !
                        <br></br>
                    </p>


                    <h2 className="catch-phrase"><u>Quelle est la durée de conservation de vos données ?</u> </h2>

                    <table className="table mt-5">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Finalité</th>
                                <th scope="col">Sous-finalités</th>
                                <th scope="col">Durée de conservation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan="4">Gestion de la communication et information externe </td>
                                <td>Mise à disposition des Sites internet et gestion de son bon fonctionnement (logs)</td>
                                <td>6 mois maximum</td>
                            </tr>

                            <tr>
                                <td>Envoi de communications sur les Services Aliz</td>
                                <td>3 ans après le dernier contact sauf si le désabonnement intervient avant </td>
                            </tr>

                            <tr>
                                <td>Réponse aux formulaires / demandes de contact via le Site</td>
                                <td>Durée strictement nécessaire pour répondre à la demande</td>
                            </tr>

                            <tr>
                                <td>Élaboration de statistiques relatives aux communications envoyées</td>
                                <td>3 ans à compter de la fin de la relation commerciale</td>
                            </tr>



                            <tr>
                                <td rowSpan="5">Gestion de la relation client </td>
                                <td>Traiter les facturations, en réalisant toute opération nécessaire (courriers, actes, prises de rendez-vous)</td>
                                <td rowSpan="2">
                                    Durée de 5 ans à compter de la fin de la relation commerciale,
                                    puis archivage pour la période durant laquelle la responsabilité de TED peut être mise en cause
                                </td>
                            </tr>
                            <tr>
                                <td>Facturation</td>
                            </tr>
                            <tr>
                                <td>Gestion des impayés et du contentieux  </td>
                                <td rowSpan="3">
                                    13 mois pour les données bancaires, à compter de la date de débit.
                                    1 an pour les pièces d’identité en cas d’exercice du droit d’accès ou de rectification.
                                </td>
                            </tr>
                            <tr>
                                <td>Gestion des demandes d’exercice de droits </td>
                            </tr>
                            <tr>
                                <td>Élaboration de statistiques commerciales </td>
                            </tr>


                            <tr>
                                <td rowSpan="2">Gestion de la relation prospect</td>
                                <td>Envoi de communication commerciale. </td>
                                <td>3 ans à compter de la collecte des données par TED ou du dernier contact avec le prospect. </td>
                            </tr>
                            <tr>
                                <td>Répondre à une demande de devis </td>
                                <td>Durée nécessaire pour répondre à la demande et ses suites. </td>

                            </tr>
                        </tbody>
                    </table>






                    <h2 className="catch-phrase"><u>Les droits sur vos données</u> </h2>

                    <p className="conditionsTerms-text">
                        Conformément et dans les limites de la Réglementation, notamment les articles 15 à 22 du RGPD,
                        vous bénéficiez des droits suivants concernant le traitement de vos données.
                        <br></br>
                        Vous pouvez exercer vos droits via l’adresse de contact.
                        En cas de doute un justificatif d’identité pourra vous être demandé.
                        Nous nous engageons à répondre aux demandes d'exercice des droits des utilisateurs dans les meilleurs délais et,
                        en tout état de cause, dans le respect des délais légaux.
                    </p>


                    <p className="conditionsTerms-text">
                        <b>Droit de retirer votre consentement</b>
                        Vous pouvez, à tout moment, retirer votre consentement à recevoir des actualités de Ted :
                    </p>

                    <ul className="conditionsTerms-text">
                        <li>en cliquant sur le lien de désinscription fourni dans chacune de nos communications sur les Services Aliz,</li>
                        <li>en nous contactant directement.</li>
                    </ul>

                    <p className="conditionsTerms-text">
                        <b>Droit d’accès </b>
                        Obtenir des informations sur ses données et une copie de ses données.
                        <br></br>
                        <br></br>
                        <b>Droit d’information </b>
                        Obtenir des informations sur les conditions de traitements (destinataires, finalités, catégories de données, etc.).
                        <br></br>
                        <br></br>
                        <b>Droit de rectification</b>
                        Corriger ou mettre à jour ses données personnelles quand elles sont inexactes ou incomplètes.
                        <br></br>
                        <br></br>
                        <b>Droit d’opposition</b>
                        S’opposer à la réalisation d’un traitement pour des raisons tenant à sa situation particulière ou
                        mis en œuvre à des fins de prospection commerciale.
                        <br></br>
                        <br></br>
                        <b>Droit à la limitation</b>
                        Demander à ne pas traiter tout ou partie des données de façon temporaire, sans pour autant demander à les effacer.
                        <br></br>
                        <br></br>
                        <b>Droit à l’effacement</b>
                        Demander la suppression de ses données personnelles

                        <br></br>
                        <br></br>
                        <b>Droit à la portabilité </b>
                        Obtenir les données dans un format structuré et lisible par une machine et pouvoir les transmettre facilement à un tiers.
                        <br></br>
                        <br></br>
                        <b>Droit de définir des directives post-mortem </b>
                        Définir les directives sur l’utilisation de vos données après votre mort.
                    </p>






                    <h2 className="catch-phrase">Demandes et réclamations</h2>

                    <p className="conditionsTerms-text">
                        En cas de question concernant vos données personnelles,
                        vous pouvez nous contacter en nous envoyant un mail à l'adresse dpo@aliz.io.
                        <br></br>
                        À défaut de réponse satisfaisante de notre part,
                        vous pouvez contacter l’autorité de contrôle compétente, en France la « Cnil »
                        Commission Nationale de l'Informatique et des Libertés située 3 Place de Fontenoy - TSA 80715, 75334 PARIS CEDEX 07.
                    </p>

                </div>
            </div>

        </div >
    )
}

