import React from 'react'
import JumpMan from '../../images/Home/jumpman.png'
import JumpManWebp from '../../images/Home/jumpman.webp'

export default function Presentation() {
    return (
        <div id="presentation-block">

            {/* Catch phrase & Cta */}
            <div id="catch-phrase" className="col-10 col-sm-8 col-lg-7 col-xl-7">
                <h1>
                    AlizFacturation
                </h1>

                <p>PROFFESIONNELS DE SANTE - Automatiser l'envoie de vos factures !</p>

                <p>
                    Aliz Facturation est un module complémentaire GRATUIT à Google Sheets™ qui vous permet de gérer automatiquement l'envoie de vos factures.
                    <br></br>
                    En une minute, vous pourrez :
                </p>

                <ul>
                    <li>Auto incrémenter les IDs de vos factures.</li>
                    <li>Accéder au menu Aliz Facturation depuis votre feuille directement.</li>
                    <li>Contactez le support Aliz Facturation depuis votre feuille.</li>
                    <li>Gérer votre espace Aliz Facturation plus rapidement.</li>
                </ul>

                <p>Aliz Facturation est simple d'utilisation. Essayez-le dès aujourd'hui pour vos factures ! Plus de 10 000 Factures sont envoyées chaque mois !</p>

                <ul>
                    <li>Notre équipe vous aide à faire la mise en service de votre assistant : souscription, création de votre espace client... </li>
                    <li>AlizFacturation est disponible 24h/24 et génère vos factures toute les heures.</li>
                    <li>Bénéficiez du support et des avantages Aliz.</li>
                    <li>Et profitez du temps gagné pour ce qui compte vraiment pour vous!.. </li>
                </ul>

                {/* <a className="col-md-8 col-lg-10 col-xl-7 d-block btn-alizFull" href="#presentation">Essayer maintenant</a> */}

            </div>
            {/* End catch phrase */}
            <div id="illustration" className="col-12 col-lg-5 col-xl-5">
                <picture>
                    <source srcSet={JumpManWebp} type="image/webp" />
                    <img id="jumpman" src={JumpMan} alt="Jump man happy with bubble yelllow in background" />
                </picture>

            </div>
        </div>

    )
}
