import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

import LogoBlue from "../../images/Logo/Logo Couleur_Sans texte.png"

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function HeaderDesktop(props) {

    const [token, setToken] = useState(props.token)
    const [disconnectedUser, setDisconnectedUser] = useState(false)

    const alizUrl = process.env.REACT_APP_PUBLIC_URL_ALIZ


    // check if user is already logged
    useEffect(() => {
        setToken(props.token)
    }, [props]);


    // Log off user 
    const disconnected = () => {
        if (localStorage.getItem('tokenAliz')) {
            setDisconnectedUser(true)
        }
    }
    useEffect(() => {
        if (disconnectedUser) {
            localStorage.removeItem('tokenAliz');
            window.location.href = "/";
        }
    }, [disconnectedUser]);


    return (
        <header id="headerDesktop">
            <nav className="navbar navbar-expand-xl">

                {/* Logo */}
                <Link className="navbar-brand" to="/">
                    <LazyLoadImage
                        alt={"Aliz Logo"}
                        src={LogoBlue}
                        effect="blur" />
                    <div><p>Aliz</p><b>Facturation</b></div>
                </Link>

                {/* This block is responsive  */}
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    {/* Nav left */}
                    <ul className="navbar-nav mr-auto">

                        <li className="nav-item">
                            <Link to="/conditions-et-service">Conditions et Service</Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/politique-de-confidentialite">Politique de confidentialité</Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/support">Support</Link>
                        </li>
                    </ul>



                    {/* Nav right */}
                    {(() => {
                        if (token) {
                            return <ul className="navbar-nav nav2">
                                <li className="nav-item " onClick={disconnected}><button className="btn-yellow">Déconnexion</button></li>
                            </ul>
                        } else {
                            return <ul className="navbar-nav nav2">
                                <li className="nav-item">
                                    <a href={alizUrl + "login"}>Connexion</a>
                                </li>

                                <li className="nav-item">
                                    <a href={alizUrl + "register"} className="btn-alizFull">S'inscrire</a>
                                </li>
                            </ul>
                        }
                    })()}
                    {/* End nav right */}

                </div>

            </nav>
        </header>
    )
}