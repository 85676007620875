import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './style/App.scss'
import Header from './component/Section/Header'
import Footer from './component/Section/Footer'
import Home from './component/Home/Home'
import PrivacyPolicy from './component/TermsAndConditions/PrivacyPolicy'
import Support from './component/TermsAndConditions/Support'
import TermsAndService from './component/TermsAndConditions/TermsAndService'

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <Routes>
          {/* Homepage */}
          <Route exact path="/" element={<Home />} />

          {/* Privacy Policy */}
          <Route exact path="/politique-de-confidentialite" element={<PrivacyPolicy />} />

          {/* Terms and Service */}
          <Route exact path="/conditions-et-service" element={<TermsAndService />} />

          {/* Support */}
          <Route exact path="/support" element={<Support />} />
        </Routes>

        <Footer />
      </div >
    </Router>
  );
}

export default App;
