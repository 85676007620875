import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import gsap from "gsap"
import LogoBlue from "../../images/Logo/Logo Couleur_Sans texte.png"


export default function HeaderTablet(props) {
    const [nav, setNav] = useState(false);

    const toggledNavRef = useRef(null);
    const navRef = useRef(null)

    const [token, setToken] = useState(props.token)
    const [disconnectedUser, setDisconnectedUser] = useState(false)

    const alizUrl = process.env.REACT_APP_PUBLIC_URL_ALIZ

    const toggledNavbar = (e) => {
        e.preventDefault();
        setNav(!nav ? true : false)
    }

    useEffect(() => {
        if (!nav) {
            gsap.to(toggledNavRef.current, { display: 'none', opacity: 0, duration: 0.8 });
            gsap.to(navRef.current, { boxShadow: 'unset', backgroundColor: "transparent", duration: 0.8 });
        } else {
            gsap.to(toggledNavRef.current, { display: 'block', opacity: 1, duration: 0.8 });
            gsap.to(navRef.current, { boxShadow: '0 0 10px 10px #5887FF1F', backgroundColor: "white", duration: 0.8 });
        }
    }, [nav])

    // check if user is already logged
    useEffect(() => {
        setToken(props.token)
    }, [props]);


    // Log off user 
    const disconnected = () => {
        if (localStorage.getItem('tokenAliz')) {
            setDisconnectedUser(true)
        }
    }

    useEffect(() => {
        if (disconnectedUser) {
            localStorage.removeItem('tokenAliz');
            window.location.href = "/";
        }
    }, [disconnectedUser]);



    return (
        <header id="headerTablet">

            <nav className="navbar navbar-expand-xl" ref={navRef}>

                {/* Logo */}
                <Link className="navbar-brand" to="/"><img src={LogoBlue} alt="Aliz Logo" />
                    <div>
                        <p>Aliz</p>
                        <b>Facturation</b>
                    </div>
                </Link>

                {/* Button toggled responsive */}
                <button onClick={toggledNavbar} id="btn-toggle">
                    <span>
                        <svg className="bi bi-filter-right" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z" />
                        </svg>
                    </span>
                </button>

                {/* Toggled navbar  */}
                <div className="collapse navbar-collapse" id="navbarNavDropdown" ref={toggledNavRef}>

                    {/* Nav left */}
                    <ul className="navbar-nav mr-auto">

                        <div className="dropdown-divider"></div>

                        <li className="nav-item item-black">
                            <Link to="/conditions-et-service">Conditions et Service</Link>
                        </li>

                        <li className="nav-item item-black">
                            <Link to="/politique-de-confidentialite">Politique de confidentialité</Link>
                        </li>
                        <li className="nav-item item-black">
                            <Link to="/support">Support</Link>
                        </li>
                    </ul>
                    {/* End nav left */}

                    {(() => {
                        if (!token) {
                            return <ul className="navbar-nav nav2">

                                <div className="dropdown-divider"></div>

                                <li className="nav-item">
                                    <a className="col-10 col-sm-4 col-lg-3 btn-yellow" href={alizUrl + "login"}>Connexion</a>
                                </li>

                                <div className="dropdown-divider"></div>

                                <li className="nav-item">
                                    <a className="col-10 col-sm-4 col-lg-3 btn-alizFull" href={alizUrl + "register"}>S'inscrire</a>
                                </li>
                            </ul>
                        } else {
                            return <ul className="navbar-nav nav2">
                                <div className="dropdown-divider"></div>
                                <li className="nav-item" onClick={disconnected}> <button className="btn-yellow">Déconnexion</button></li>
                            </ul>
                        }
                    })()}

                </div>
            </nav>
        </header>
    )
}